import styled from 'styled-components';

import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

export const Background = styled(BackgroundImage)`
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const PicColumn = styled.div`
  align-self: center;
`;

export const EngeloPic = styled(Image)`
  max-width: 350px;
  max-height: 350px;
  border-radius: 16px;
  margin: 0 auto;
`;

export const EngeloBio = styled.div`
  align-self: center;
`;
