import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { Hero } from '../../../components/Hero';

import { IntroText } from './components/IntroText';
import { Columns, Column, IntroColumn, Title } from './styles';
import { BuyItNowButton } from './components/BuyNowButton';

export const PageHeader: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "Hero-5.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookCover: file(relativePath: { eq: "book-cover.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Hero fluid={data.backgroundImage.childImageSharp.fluid}>
        <Columns className="columns is-hidden-mobile">
          <Column className="column" />
          <Column className="column" />
          <IntroColumn className="column">
            <Title className="title is-2 has-text-weight-bold has-text-white is-uppercase">
              <IntroText color="black" />
            </Title>
            <BuyItNowButton />
          </IntroColumn>
        </Columns>
      </Hero>
      <div className="is-hidden-tablet">
        <Image fluid={data.bookCover.childImageSharp.fluid} />
        <div className="has-text-centered">
          <BuyItNowButton />
        </div>
      </div>
    </>
  );
};
