import styled from 'styled-components';

export const SubHeader = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const Columns = styled.div`
  padding-top: 3rem;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const Column = styled.div`
  align-self: center;
`;

export const BuyItNow = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const Section = styled.section`
  padding: 4rem 1.5rem 0 1.5rem;
`;

export const InnerSection = styled.section`
  padding-top: 0;
  padding-bottom: 0;
`;

export const List = styled.ul`
  list-style: square;
  font-size: 2rem;
  line-height: 1.5rem;

  > li > span {
    vertical-align: middle;
    display: inline-table;
  }
`;
