import React from 'react';

import { Item } from './styles';

export const ListItem: React.FC = ({ children }) => (
  <Item>
    <span className="is-size-6 is-size-5-fullhd has-text-weight-bold has-text-black">
      {children}
    </span>
  </Item>
);
