import styled from 'styled-components';

import { HeaderText } from '../../../components/HeaderText';

export const Content = styled.div`
  padding: 3rem 0 6rem 0;
`;

export const Header = styled(HeaderText)`
  padding-bottom: 3rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;
