import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HeaderText } from '../../../components/HeaderText';

interface IBasicColumnProps {
  disabled?: boolean;
}

export const Content = styled.div`
  padding: 3rem 0;
`;

const BasicColumn = styled.div`
  @media screen and (max-width: 768px) {
    &&& {
      border-radius: 0;
      margin-bottom: 2rem;
    }
  }

  .button {
    pointer-events: ${(props: IBasicColumnProps): string =>
      props.disabled ? 'none' : 'auto'};
  }
`;

export const Column = styled(BasicColumn)`
  border: 1px solid black;
  align-self: center;
`;

export const MiddleColumn = styled(BasicColumn)`
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 1rem;
`;

export const ListOfThings = styled.div`
  padding-bottom: 3rem;
`;

export const List = styled.ul`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media screen and (max-width: 1410px) {
    margin: 0 auto;
    max-width: 260px;
  }
`;

export const Item = styled.li`
  margin-bottom: 0.5rem;

  @media screen and (max-width: 1410px) {
    font-size: 0.75rem;
  }

  @media screen and (min-width: 1240px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  &&& {
    width: 2rem;
  }
`;

export const Header = styled(HeaderText)`
  padding-bottom: 3rem;
`;

export const BuyNow = styled.div`
  padding: 1.5rem;
`;

export const RetailerColumns = styled.div`
  align-items: center;

  > .column {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 1024px) {
    &.columns {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }
`;
