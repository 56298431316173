import React from 'react';

interface IIntroText {
  color?: 'white' | 'black';
}

export const IntroText: React.FC<IIntroText> = ({ color = 'white' }) => (
  <span className={`has-text-${color}`}>
    The most provocative and controversial real estate investing book
    <span className="has-text-primary is-uppercase"> ever </span>
    written.
  </span>
);
