import React from 'react';

import { AMAZON_LINK } from '../../../../../constants';

export const BuyItNowButton: React.FC = () => (
  <a
    href={AMAZON_LINK}
    target="_blank"
    rel="noopener noreferrer"
    className="button is-primary is-large is-uppercase"
  >
    Buy it Now
  </a>
);
