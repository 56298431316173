import Image, { GatsbyImageProps } from 'gatsby-image';
import styled from 'styled-components';

import { IBgImageProps } from './types';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const Overlay = styled.div`
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const BgImage: React.FC<GatsbyImageProps> = styled(Image)<IBgImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: ${(props): string => props.height || '100vh'};

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  // Adjust image positioning (if image covers area with defined height) and add
  // font-family for polyfill
  & > img {
    object-fit: ${(props): string => props.fit || 'cover'} !important;
    object-position: ${(props): string =>
      props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${(props): string =>
      props.fit || 'cover'} !important; object-position: ${(props): string =>
  props.position || '50% 50%'} !important;'
  }
`;
