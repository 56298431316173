import React, { FormEvent } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import {
  faSkullCrossbones,
  faBlind,
  faGrinStars,
  faCandyCane,
  faPoop,
} from '@fortawesome/free-solid-svg-icons';

import {
  AMAZON_LINK,
  BOOKS_A_MILLION_LINK,
  BARNES_AND_NOBLE_LINK,
  EBOOK_AMAZON_LINK,
  AUDIO_BOOK_AMAZON_LINK,
} from '../../../constants';

import { ColumnHeader } from './components/ColumnHeader';
import { Highlight } from './components/Highlight/Highlight';
import {
  BuyNow,
  Content,
  Column,
  Header,
  MiddleColumn,
  ListOfThings,
  List,
  Item,
  Icon,
  RetailerColumns,
} from './styles';

export const Buy: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      amazonLogo: file(relativePath: { eq: "2000px-Amazon_logo_plain.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 256) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bamLogo: file(relativePath: { eq: "Books-A-Million_logo.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 256) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      barnesAndNobleLogo: file(
        relativePath: { eq: "barnes-and-noble-logo-png-10.png" }
      ) {
        childImageSharp {
          fixed(quality: 90, width: 256) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Content>
      <div className="container">
        <section className="section">
          <Header color="black">How to buy the book</Header>
          <div className="columns is-gapless">
            <Column className="column">
              <section className="section is-paddingless">
                <ColumnHeader textColor="black" addPadding>
                  Kindle
                </ColumnHeader>
                <div>
                  <div className="has-text-centered is-size-1 is-size-3-mobile is-uppercase has-text-black has-text-weight-bold">
                    $9.99
                  </div>
                  <BuyNow className="has-text-centered">
                    <a
                      href={EBOOK_AMAZON_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button is-danger is-uppercase is-large is-fullwidth"
                    >
                      Order Now
                    </a>
                  </BuyNow>
                </div>
              </section>
            </Column>
            <MiddleColumn className="column">
              <section className="section is-paddingless">
                <ColumnHeader textColor="white">
                  Paperback Pre-Order
                </ColumnHeader>
                <div className="has-text-centered has-text-white has-text-weight-bold is-size-5 is-size-6-mobile">
                  Inside you will learn:
                </div>
                <ListOfThings className="is-size-6 has-text-white">
                  <List>
                    <Item>
                      <Icon
                        className="has-text-primary"
                        icon={faSkullCrossbones}
                      />
                      Why the American Dream is <Highlight>dead</Highlight>
                    </Item>
                    <Item>
                      <Icon className="has-text-primary" icon={faBlind} />
                      How to negotiate like a <Highlight>pimp</Highlight>
                    </Item>
                    <Item>
                      <Icon className="has-text-primary" icon={faGrinStars} />
                      How to flip like a <Highlight>pornstar</Highlight>
                    </Item>
                    <Item>
                      <Icon className="has-text-primary" icon={faCandyCane} />
                      Why most realtors <Highlight>suck</Highlight>
                    </Item>
                    <Item>
                      <Icon className="has-text-primary" icon={faPoop} />
                      The <Highlight>biggest lie</Highlight> in real estate
                    </Item>
                  </List>
                  <div className="has-text-centered has-text-white has-text-weight-bold is-size-5 is-size-6-mobile">
                    And much much more...
                  </div>
                </ListOfThings>
                <div className="has-text-centered is-size-1 is-size-3-mobile is-uppercase has-text-white has-text-weight-bold">
                  $17.95
                </div>
                <BuyNow className="has-text-centered">
                  <a
                    href={AMAZON_LINK}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="button is-primary is-uppercase is-large is-fullwidth"
                  >
                    Order Now
                  </a>
                </BuyNow>
              </section>
            </MiddleColumn>
            <Column className="column is-hidden-mobile">
              <section className="section is-paddingless">
                <ColumnHeader textColor="black" addPadding>
                  Audio
                </ColumnHeader>
                <div>
                  <div className="has-text-centered is-size-1 is-size-3-mobile is-uppercase has-text-black has-text-weight-bold">
                    $14.99
                  </div>
                  <BuyNow className="has-text-centered">
                    <a
                      href={AUDIO_BOOK_AMAZON_LINK}
                      rel="noreferrer noopener"
                      target="_blank"
                      className="button is-danger is-uppercase is-large is-fullwidth"
                    >
                      Order Now
                    </a>
                  </BuyNow>
                </div>
              </section>
            </Column>
          </div>
        </section>
        <section>
          <RetailerColumns className="columns">
            <div className="column has-text-centered">
              <a href={AMAZON_LINK} rel="noopener noreferrer" target="_blank">
                <Image
                  fixed={data.amazonLogo.childImageSharp.fixed}
                  alt="Amazon"
                />
              </a>
            </div>
            <div className="column has-text-centered">
              <a
                href={BOOKS_A_MILLION_LINK}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  fixed={data.bamLogo.childImageSharp.fixed}
                  alt="Books-A-Million"
                />
              </a>
            </div>
            <div className="column has-text-centered">
              <a
                href={BARNES_AND_NOBLE_LINK}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  fixed={data.barnesAndNobleLogo.childImageSharp.fixed}
                  alt="Barnes and Noble"
                />
              </a>
            </div>
          </RetailerColumns>
        </section>
      </div>
    </Content>
  );
};
