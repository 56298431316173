export const red = '#e74c3c';

export const AMAZON_LINK =
  'https://www.amazon.com/Raw-Truth-Success-Real-Estate/dp/1631950894/';
export const BARNES_AND_NOBLE_LINK =
  'https://www.barnesandnoble.com/w/the-raw-truth-to-success-in-real-estate-engelo-rumora/1136687087?ean=9781631950896';
export const BOOKS_A_MILLION_LINK =
  'https://www.booksamillion.com/p/Raw-Truth-Success-Real-Estate/Engelo-Rumora/9781631950896?id=7550123930867';
export const EBOOK_AMAZON_LINK =
  'https://www.amazon.com/Raw-Truth-Success-Real-Estate-ebook/dp/B08H6P77QV/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr=';
export const AUDIO_BOOK_AMAZON_LINK =
  'https://www.amazon.com/Raw-Truth-Success-Real-Estate/dp/B08JNQH52N/ref=tmm_aud_swatch_0';
