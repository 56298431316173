import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import { Background, EngeloPic, EngeloBio, PicColumn } from './styles';
import { HeaderText } from '../../../components/HeaderText';

export const About: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "roadtexture.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      engeloImage: file(relativePath: { eq: "Engelo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Background
      Tag="div"
      fluid={data.backgroundImage.childImageSharp.fluid}
      backgroundColor="black"
    >
      <div className="container">
        <section className="section">
          <HeaderText color="white">About the Author</HeaderText>
          <div className="columns">
            <PicColumn className="column">
              <EngeloPic fluid={data.engeloImage.childImageSharp.fluid} />
            </PicColumn>
            <EngeloBio className="column">
              <span className="has-text-white is-size-5 is-size-6-mobile">
                Engelo Rumora is the Real Estate Dingo and everyone&#39;s
                favorite Australian. He quit school at the age of 14 and played
                professional soccer at the age of 18. From there, he began to
                invest in real estate and now owns real estate all over the
                world. Engelo has bought, renovated, and sold over 1000
                properties through his company, Ohio Cashflow, which is a
                two-time Inc 5000 honoree. He is also known for giving houses
                away to people in need and his crazy online videos. Engelo&#39;s
                life mission is to be remembered as someone that gave it his all
                and gave it all away.
              </span>
            </EngeloBio>
          </div>
        </section>
      </div>
    </Background>
  );
};
