import styled from 'styled-components';

export const Columns = styled.div`
  height: 100%;
  padding: 0 10rem;

  @media screen and (max-width: 1024px) {
    padding: 5rem;
  }
`;

export const Column = styled.div`
  align-self: flex-end;
`;

export const Title = styled.div`
  text-shadow: 1px 1px 1px #ffffff;
  letter-spacing: 0.15rem;
  font-family: 'Anton', sans-serif;
  padding-bottom: 1rem;

  &&& {
    @media screen and (max-width: 1200px) {
      font-size: 1.8rem;
    }
  }
`;

export const IntroColumn = styled(Column)`
  padding-bottom: 10rem;
`;
