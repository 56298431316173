import styled from 'styled-components';

import Image from 'gatsby-image';

export const Content = styled.div`
  padding-bottom: 3rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 3rem;

    &:last-child {
      padding-bottom: 0rem;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const Quote = styled.blockquote`
  &:before {
    @media screen and (max-width: 768px) {
      content '';
    }

    color: black;
    font-family: Georgia, serif;
    content: '“';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 90px;
    font-weight: normal;
    width: 75px;
    height: 75px;
    text-align: center;
    line-height: 90px;
  }

  &: after {
    @media screen and (max-width: 768px) {
      content '';
    }

    color: black;
    font-family: Georgia, serif;
    content: '”';
    display: block;
    position: absolute;
    bottom: 0;
    line-height: 300px;
    right: 0;
    font-size: 300px;
    font-weight: normal;
    width: 125px;
    height: 125px;
    text-align: center;
  }

  &&& {
    @media screen and (max-width: 768px) {
      padding: 0;
    }

    border: none;
    background-color: inherit;
    color: black;
    display: block;
    padding: 0 125px 0 75px;
    box-sizing: border-box;
    min-height: 120px;
    max-width: 90%;
    margin: 25px auto;
    position: relative;
  }

  > p {
    color: black;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.5;

    @media screen and (min-width: 768px) {
      text-align: left;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
`;

export const Media = styled.div`
  align-self: center;
`;

export const MobileMedia = styled.div`
  > div {
    margin: 0 auto;
  }
`;

export const Pic = styled(Image)`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  margin: 0 auto;
`;
