import React from 'react';

import { Content } from './styles';
import { HeaderText } from '../../../../../components/HeaderText';

interface IEntry {
  title: string;
}

export const Entry: React.FC<IEntry> = ({ title, children }) => (
  <Content className="has-text-white">
    <hr />
    <HeaderText color="primary" align="left" size={4}>
      {title}
    </HeaderText>
    <div className="has-text-white is-size-5 is-size-6-mobile">{children}</div>
  </Content>
);
