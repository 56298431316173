import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import { Testimonial } from './components/Testimonial';
import { Content, Header } from './styles';

export const Testimonials: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      jayImage: file(relativePath: { eq: "Jay.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      justinImage: file(relativePath: { eq: "Justin.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Content>
      <div className="container">
        <Header color="black">What People Are Saying</Header>
        <Testimonial
          name="Justin Stamper"
          bio="Host of Zombie House Flipping on A&amp;E"
          direction="left"
          fluid={data.justinImage.childImageSharp.fluid}
        >
          The Dingo&#39;s book is the swift kick in the arse new investors need
          to get focused and cut through the nonsense they are bombarded with
          every time they search &quot;how to get started in real estate&quot;
          and end up accidentally selling an arm and a leg to learn something
          that they could have figured out on their own had they just picked up
          this book first.
        </Testimonial>
        <Testimonial
          name="Jay Hinrichs"
          bio="Real Estate Developer and Broker"
          direction="right"
          fluid={data.jayImage.childImageSharp.fluid}
        >
          A true American success story: Engelo lands in the Land of Opportunity
          with nothing more than an incredible drive and vision for his future.
          Through the years he has built one of the premier turnkey brands in
          the U.S. His passion for his product and clients is self-evident, and
          his success is simply an American success story!
        </Testimonial>
      </div>
    </Content>
  );
};
