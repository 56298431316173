import React from 'react';

import { FluidObject } from 'gatsby-image';

import { Content, Media, MobileMedia, Pic, Quote } from './styles';

interface ITestimonial {
  direction: 'left' | 'right';
  fluid: FluidObject;
  name: string;
  bio: string;
}

export const Testimonial: React.FC<ITestimonial> = ({
  bio,
  children,
  direction,
  fluid,
  name,
}) => {
  const details = (
    <>
      <div className="has-text-centered is-size-6 has-text-gray is-italic">
        {bio}
      </div>
      <div className="has-text-centered is-size-6 has-text-weight-bold">
        {name}
      </div>
    </>
  );

  const media = (
    <Media className={`media-${direction} is-hidden-mobile`}>
      <Pic fluid={fluid} />
      {details}
    </Media>
  );

  return (
    <Content>
      <article className="media">
        {direction === 'left' && media}
        <div className="media-content has-text-centered">
          <div className="content">
            <Quote>
              <p>{children}</p>
            </Quote>
          </div>
          <MobileMedia className="is-hidden-tablet">
            <Pic fluid={fluid} />
            {details}
          </MobileMedia>
        </div>
        {direction === 'right' && media}
      </article>
    </Content>
  );
};
