import React from 'react';
import { Header } from './styles';

interface IColumnHeader {
  textColor: 'white' | 'black';
  addPadding?: boolean;
}

export const ColumnHeader: React.FC<IColumnHeader> = ({
  textColor,
  children,
  addPadding = false,
}) => (
  <Header
    className={`is-size-4 has-text-${textColor} has-text-weight-bold has-text-centered`}
    addPadding={addPadding}
  >
    {children}
  </Header>
);
