import styled from 'styled-components';

interface IHeaderProps {
  addPadding?: boolean;
}

export const Header = styled.div`
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  padding-bottom: ${(p: IHeaderProps): string =>
    p.addPadding ? '20rem' : '3rem'};

  @media screen and (max-width: 768px) {
    padding-bottom: 5rem;
  }
`;
