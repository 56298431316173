import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { ListItem } from './components/ListItem/ListItem';
import { HeaderText } from '../../../components/HeaderText';

import {
  SubHeader,
  Columns,
  Column,
  BuyItNow,
  Section,
  InnerSection,
  List,
} from './styles';
import { AMAZON_LINK } from '../../../constants';

export const ReasonsToBuy: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      bookCover: file(relativePath: { eq: "book-cover.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="container">
      <Section className="section has-text-centered">
        <HeaderText color="black">5 reasons to buy this book</HeaderText>
        <Columns className="columns">
          <Column className="column is-hidden-mobile">
            <Image fluid={data.bookCover.childImageSharp.fluid} />
          </Column>
          <Column className="column">
            <InnerSection className="section has-text-centered">
              <div className="is-hidden-mobile">
                <HeaderText color="black" size={5}>
                  Out of the thousands of other real estate books, why choose
                  this one?
                </HeaderText>
              </div>
              <List className="has-text-left">
                <ListItem>
                  Because there is absolutely no other real estate book like it!{' '}
                  <a
                    href={AMAZON_LINK}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    See for yourself.{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </ListItem>
                <ListItem>
                  It will kick you in the{' '}
                  <span className="has-text-primary">A$$</span> and
                  revolutionize your way of thinking with its unorthodox
                  approach.
                </ListItem>
                <ListItem>
                  It offers timeless real estate investment principles that can
                  be implemented in any market and under any circumstances.
                </ListItem>
                <ListItem>
                  There is no hidden agenda and it&#39;s written by someone who
                  is still in the trenches.
                </ListItem>
                <ListItem>
                  You will learn how to buy properties cheaper than you ever
                  thought possible. So cheap you might get arrested for
                  stealing.
                </ListItem>
              </List>
              <SubHeader>
                <span className="is-size-5 is-size-6-mobile is-size-3-fullhd has-text-weight-bold has-text-black">
                  And much much more!
                </span>
              </SubHeader>
              <span className="is-size-6 is-size-5-fullhd has-text-weight-bold has-text-black is-hidden-mobile">
                If you&#39;re willing to work harder than you have ever worked,
                with an unwavering commitment and laser-sharp focus on your end
                goal, then this book is for you.
              </span>
              <BuyItNow className="has-text-centered">
                <a
                  href={AMAZON_LINK}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="button is-primary is-large is-uppercase"
                >
                  buy it now
                </a>
              </BuyItNow>
            </InnerSection>
          </Column>
        </Columns>
      </Section>
      <hr />
    </div>
  );
};
