import React from 'react';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

import { ReasonsToBuy } from '../page-components/index/ReasonsToBuy';
import { Testimonials } from '../page-components/index/Testimonials';
import { About } from '../page-components/index/About';
import { Buy } from '../page-components/index/Buy';
import { FAQ } from '../page-components/index/FAQ';
import { PageHeader } from '../page-components/index/PageHeader';

const IndexPage: React.FC = () => {
  return (
    <>
      <Layout>
        <SEO title="Home" />
        <PageHeader />
        <ReasonsToBuy />
        <Testimonials />
        <About />
        <Buy />
        <FAQ />
      </Layout>
    </>
  );
};

export default IndexPage;
