import React from 'react';

import { HeaderText } from '../../../components/HeaderText';

import { Content } from './styles';
import { Entry } from './components/Entry';

export const FAQ: React.FC = () => (
  <Content>
    <div className="container">
      <section className="section">
        <HeaderText color="white">FAQ</HeaderText>
        <Entry title="Who is this book for?">
          Beginner, intermediary, and experienced investors. I truly believe
          that there is something valuable within the book for everyone. Even if
          it&#39;s just one piece of content that helps. My mission is
          accomplished.
        </Entry>
        <Entry title="Why did you write this book?">
          Because I was sick and tired of seeing all of the other &#34;Something
          for nothing&#34; books that make it sound all so super easy. Nothing
          comes easy in life, and neither does success investing in real estate.
        </Entry>
        <Entry title="Why are you so rude?">
          Why are you so weak? The last time I checked it was the 21st century
          and we live in a world full of pain and suffering. I say it as it is
          and mean no harm in doing so. If a few brash words from someone you
          have never met catch you off guard, ask yourself what other things are
          stopping you from getting to where you want to be?
        </Entry>
        <Entry title="I have no money, can I still invest?">
          I'm sorry to tell you this, sweet pea, but &#34;money makes
          money&#34;. You will need some of it to get started in real estate
          investing. If you want to cry about your current situation I suggest
          you don&#39;t buy this book. If you want to work harder than you have
          ever worked before then this book is for you! Nothing beats hard work,
          remember that.
        </Entry>
        <Entry title="I have a different question.">
          Contact me:{' '}
          <a className="has-text-white" href="mailto:admin@engelorumora.com">
            admin@engelorumora.com
          </a>
        </Entry>
      </section>
    </div>
  </Content>
);
