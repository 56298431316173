import React from 'react';
import { GatsbyImageProps } from 'gatsby-image';

import { IBgImageProps } from './types';
import { Container, BgImage, Overlay } from './styles';

export const Hero: React.FC<GatsbyImageProps & IBgImageProps> = ({
  children,
  ...props
}) => {
  return (
    <div className="is-hidden-mobile">
      <Container>
        <BgImage {...props} />
        <Overlay>{children}</Overlay>
      </Container>
    </div>
  );
};
